import "./about.scss";
import "./experience";
import Experience from "./experience";

function About() {
	return (
		<div className="About">
			<Experience
				svgPath="UTAS.svg"
				svgAlt="University of Tasmania Logo"
				title="University of Tasmania"
				subTitle="High Achiever Program"
				description="In 2020, I completed a year long course offered to high academic achievers throughout my year 12 studies. This course included both introductory software development and AI/ML courses which further cemented my love of computer science and software development."
			/>
			<Experience
				svgPath="ANU.svg"
				svgAlt="Australian National University Logo"
				title="Australian National University"
				subTitle="Bachelor of Computing"
				description="I then went on to study at ANU, completing my degree in 2024. During this time, I primarily focused on data structures, algorithms, computer architecture, networks, and software development processes, but I also took several Korean language courses."
			/>
			<Experience
				svgPath="AD.svg"
				svgAlt="Agile Digital Logo"
				title="Agile Digital"
				subTitle="Software Developer"
				description="During my second year at ANU, I began working at Agile Digital, a Canberra-based software development company. This role provided me with valuable work experience as I developed several internal tools and completed client projects, allowing me to hone my skills as a software engineer."
			/>
			<Experience
				svgPath="Atlassian.svg"
				svgAlt="Atlassian Logo"
				title="Atlassian"
				subTitle="Intern and Graduate Software Developer"
				description="During the summer of 2023-24, I interned at Atlassian, where I worked with the Sandbox team on integrating the Validation Engine into the data migration pipeline. This allowed users to perform preflight checks before starting a migration. Following this internship, I received a graduate offer and will begin working full-time as a software developer in 2025."
			/>
		</div>
	);
}

export default About;
