import React from "react";
import ReactDOM from "react-dom/client";
import "./style.scss";
import Home from "./home/home";
import About from "./about/about";
import Contact from "./contact/contact";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<Helmet>
			<meta
				name="description"
				content="Leon Zolati, a junior full stack developer based in Australia. ANU Computing graduate with software engineering experience at Atlassian and Agile Digital."
			/>
			<meta
				name="keywords"
				content="Leon Zolati, Full Stack Developer, Software Engineer, ANU, Atlassian, Agile Digital, junior developer, Australia, AI, machine learning"
			/>
			<meta
				property="og:title"
				content="Leon Zolati | Full Stack Developer | Atlassian"
			/>
			<meta
				property="og:description"
				content="Leon Zolati is a full stack developer based in Australia with experience at Atlassian and Agile Digital. ANU graduate with a focus on software engineering."
			/>
			<meta property="og:image" content="https://www.zolati.dev/icon.png" />
			<meta property="og:url" content="https://www.zolati.dev" />
			<meta property="og:type" content="website" />
			<meta name="author" content="Leon Zolati" />
			<title>
				Leon Zolati | Full Stack Developer | Australia | ANU Alumnus | Atlassian
				Graduate
			</title>
		</Helmet>

		<Home />
		<About />
		<Contact />
	</React.StrictMode>
);
